import React, { useEffect, useState } from "react";
import styles from "./ApplicationSummaryCard.module.scss";
import { CardTick, ReceiptEdit, Mouse, UsdCoin, SmsEdit, Call, Location, PlayCircle, CardRemove, MessageNotif, PathTool, TaskSquare } from "iconsax-react";
import { ApplicationStage, Button, CloseIcon, LineWithIcon } from "common";
import LoanApplicationHandler from "actions/LoanApplicationHandler";
import { useNavigate } from "react-router-dom";
import Analytics, { ITracking } from "classes/Analytics";
import { concatenateAddress, isAmountValid, isStringPresent } from "utils/helpers";
import { IAddress, IHighlight, IList } from "common/interfaces";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "reducers/Hooks";
import { APPLICATION_SOURCE, APP_STATUSES, OFFER_TYPE } from "utils/constants";
import { formatAmount2, formatAPICurrency, formatAPIDate, formatAPIDateFullYear, formatFullname, formatLenderTypeAndSource, formatPhone } from "utils/formatters";
import actions from "reducers/BorrowerReducer";

interface IApplicationSummary {
  applicantId: string;
  closeApplicationSummary: () => void;
  active: boolean;
}

const ApplicationSummaryCard: React.ForwardRefRenderFunction<HTMLDivElement, IApplicationSummary> = ({ applicantId, closeApplicationSummary, active }, ref) => {
  const [details, setDetails] = useState(null);
  const [fundedOffers, setFundedOffers] = useState<Array<any>>([]);
  const applicationOffers = useAppSelector(state => state.borrower.applicationOffers);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getOffers = async (next: string): Promise<IList> => {
    let offersLocal = await LoanApplicationHandler.getOffers(next, applicantId);
    offersLocal.results = offersLocal.results.map(result => {
      return {
        ...result,
        lender: result.lender_name,
        dateTime: formatAPIDate(result.date_time),
        offerType: result.pre_approved ? OFFER_TYPE.PRE_APPROVED : (result.pre_qualified ? OFFER_TYPE.PRE_QUALIFIED : "-"),
        termOfLoan: `${result.term} ${result.term_unit?.toLowerCase()}${result.term > 1 ? 's' : ''}`,
        monthlyPayment: formatAPICurrency(parseFloat(result.monthly_payment)),
        monthlyPaymentTerm: formatAPICurrency(parseFloat(result.monthly_payment)) + "/" + (result.term_unit as string).toLowerCase().substring(0, 2),
        maxLoan: formatAPICurrency(parseFloat(result.amount)),
        apr: `${parseFloat(result.apr).toFixed(2)}% ${result.apr_type}`,
        monthly_payment: parseFloat(result.monthly_payment),
        max_loan: parseFloat(result.amount),
        apr_num: parseFloat(result.apr),
        status: result.status,
        funded_amount: parseFloat(result.funded_amount || "0"),
        fundedAmount: formatAPICurrency(parseFloat(result.funded_amount || "0")),
        highlight: { highlighted: result?.status === APP_STATUSES.FUNDED, property: "lender_name" } as IHighlight
      }
    });

    offersLocal.results = _.filter(offersLocal.results, item => { return item.status !== APP_STATUSES.CANCELED });
    dispatch(actions.setApplicationOffers(offersLocal.results));

    return offersLocal;
  }

  useEffect(() => {
    const fundedOffersTemp = [];
    _.forEach(applicationOffers, offer => {
      if (offer?.status === APP_STATUSES.FUNDED) {
        fundedOffersTemp.push(offer);
      }
    })
    setFundedOffers(fundedOffersTemp);
  }, [applicationOffers]);

  const getFundedOffersLenders = (): string => {
    let fundedOffersLenders = "";
    if (fundedOffers.length > 1) {
      fundedOffers.forEach((fundedOffer) => {
        fundedOffersLenders += fundedOffer.lender_name + ", ";
      });
      fundedOffersLenders = fundedOffersLenders.replace(/,\s*$/, "");
      return fundedOffersLenders;
    }
  }

  useEffect(() => {
    if (applicantId) {
      getApplication();
      getOffers(`loan-applications/applications/${applicantId}/offers/?offset=0&limit=1000000`);
    }
  }, [applicantId]);

  const getApplication = () => {
    LoanApplicationHandler.get(applicantId)
      .then(response => {
        setDetails(response);
      });
  }

  const address: IAddress = {
    address1: details?.address1,
    city: details?.city,
    state: details?.state,
    postal_code: details?.postal_code,
  };

  const handleMoreInfoClick = () => {
    if (applicantId) {
      Analytics.track({ experience: "portal", screen: "application_summary", object: "more_information_button", action: "clicked" } as ITracking, { application_id: details?.id, merchant_id: details?.merchant?.id });
      navigate(`/viewApplicant/${applicantId}/application`);
    }
  }

  return <>
    <div id="applicationSummaryWrapper" className={active ? "applicationSummaryActive" : ""} ref={ref}>
      <div className={styles.card}>
        <CloseIcon id="applicationSummary" color="dark" onClose={closeApplicationSummary} />

        <h2 style={{ color: "var(--primaryVariationTextColor)" }}>{formatFullname(details?.first_name, details?.last_name)}</h2>

        <LineWithIcon icon={SmsEdit} text={details?.email} />
        <LineWithIcon icon={Call} text={formatPhone(details?.phone_number)} />
        <LineWithIcon icon={Location} text={concatenateAddress(address)} />
        <LineWithIcon icon={MessageNotif} text={formatLenderTypeAndSource(details?.lender_type, details?.source)} />
      </div>

      <div className={styles.card2}>
        <h2 style={{ color: "var(--primaryVariationTextColor)" }}>Loan Information</h2>
        {/* clicked and funded applications can't be cancelled */}
        {details?.lender_type === "DTC" && <>
          <ApplicationStage icon={PlayCircle} text="Started" completed={isStringPresent(details?.created_at)} date={formatAPIDateFullYear(details?.created_at, false, false, true)} showLine={true} />
          <ApplicationStage icon={ReceiptEdit} text="Requested" completed={isStringPresent(details?.submitted_at)} amount={formatAmount2(details?.loan_amount)} date={formatAPIDateFullYear(details?.submitted_at)} showLine={true} />
          <ApplicationStage icon={UsdCoin} text="Offered" completed={isStringPresent(details?.offered_at)} date={formatAPIDateFullYear(details?.offered_at, false, false, true)} showLine={true} />
          <ApplicationStage icon={Mouse} text="Clicked" completed={isStringPresent(details?.clicked_at)} date={formatAPIDateFullYear(details?.clicked_at, false, false, true)} showLine={true} />
          <ApplicationStage icon={CardTick} text="Funded" completed={isAmountValid(details?.funded_amount)} amount={isAmountValid(details?.funded_amount) ? formatAmount2(details?.funded_amount) : ""} date={formatAPIDateFullYear(details?.funded_at, false, false, true)} showLine={false} />
        </>}

        {details?.lender_type === "DTM" && <>
          {/* if application is already prequalified, don't show started stage at all */}
          {/* signed, ready for funding and funded applications can't be cancelled */}
          {!isStringPresent(details?.offered_at) && <ApplicationStage icon={PlayCircle} text="Started" completed={isStringPresent(details?.created_at)} date={formatAPIDateFullYear(details?.created_at, false, false, true)} showLine={true} />}
          {/* prequalified and not yet offered is showed when application has offered_at and has PENDING_WITH_MERCHANT status, but this can't be used to highlight it */}
          {details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={ReceiptEdit} text={details?.source === APPLICATION_SOURCE.INVITATION ? "Requested" : "Prequalified"} completed={isStringPresent(details?.source === APPLICATION_SOURCE.INVITATION ? details?.submitted_at : details?.offered_at)} amount={formatAmount2(details?.loan_amount)} date={formatAPIDateFullYear(details?.source === APPLICATION_SOURCE.INVITATION ? details?.submitted_at : details?.offered_at, false, false, true)} showLine={true} />}
          {details?.status === APP_STATUSES.NO_OFFERS && <ApplicationStage icon={CardRemove} text="No offers" completed={details?.status === APP_STATUSES.NO_OFFERS} showLine={false} date={formatAPIDateFullYear(details?.submitted_at, false, false, true)} />}
          {details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={UsdCoin} text="Offered" completed={isStringPresent(details?.offered_at)} amount={formatAmount2(applicationOffers[0]?.amount, true)} date={formatAPIDateFullYear(details?.offered_at, false, false, true)} showLine={true} />}
          {details?.status !== APP_STATUSES.CANCELED && details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={PathTool} text="Signed" completed={isStringPresent(details?.pending_with_lender_at)} amount={formatAmount2(applicationOffers[0]?.amount, true)} date={formatAPIDateFullYear(details?.pending_with_lender_at, false, false, true)} showLine={true} />}
          {details?.status !== APP_STATUSES.CANCELED && details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={TaskSquare} text="Ready" completed={isStringPresent(details?.ready_for_funding_at)} amount={formatAmount2(applicationOffers[0]?.amount, true)} date={formatAPIDateFullYear(details?.ready_for_funding_at, false, false, true)} showLine={true} />}
          {details?.status !== APP_STATUSES.CANCELED && details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={CardTick} text="Funded" completed={isAmountValid(details?.funded_amount)} amount={isAmountValid(details?.funded_amount) ? formatAmount2(details?.funded_amount) : ""} date={formatAPIDateFullYear(details?.funded_at, false, false, true)} showLine={false} />}
          {details?.status === APP_STATUSES.CANCELED && details?.status !== APP_STATUSES.NO_OFFERS && <ApplicationStage icon={CardRemove} text="Cancelled" completed={isAmountValid(details?.canceled_at)} date={formatAPIDateFullYear(details?.canceled_at, false, false, true)} showLine={false} />}
        </>}

        {isAmountValid(details?.funded_amount) && fundedOffers.length > 0 && <div className={styles.cardHeader}>
          <div>
            {fundedOffers.length === 1 && <>
              <h4 className={styles.offerLenders}>{formatAmount2(fundedOffers[0].funded_amount)} offer with {fundedOffers[0].lender_name}</h4>
              <div className={styles.offerDetailsWrapper}>
                <p className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].monthlyPayment}</p>
                <p className={styles.offerDetails}>/{(fundedOffers[0].term_unit as string).toLowerCase()} for </p>
                <p className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].term}</p>
                <p className={styles.offerDetails}> {(fundedOffers[0].term_unit as string).toLowerCase()}s | </p>
                <p className={styles.offerDetails + " " + styles.important}>{fundedOffers[0].apr_num}%</p>
                <p className={styles.offerDetails}> APR</p>
              </div>
            </>
            }
            {(fundedOffers.length > 1) && <>
              <p>{formatAmount2(details?.funded_amount)} with multiple offers</p>
              <p>{getFundedOffersLenders()}</p>
            </>
            }
          </div>
        </div>
        }

        <div className={styles.moreInfoWrapper}>
          <Button
            id="btn_moreInformation"
            label="More information"
            onClick={handleMoreInfoClick}
            className={styles.moreInfoButton}
          />
        </div>
      </div>
    </div>
  </>;
}

export default React.forwardRef(ApplicationSummaryCard);
