import { useAuth } from 'auth/useAuth';
import LocalStorage from 'classes/LocalStorage';
import Observer, { EVENTS } from 'classes/Observer';
import { IUser } from 'common/interfaces';
import { BusinessModelType } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/Hooks';
import { PROGRAM_VIEWS } from 'utils/constants';
import useMerchant from './useMerchant';

export const useBusinessModel = () => {
  const [selectedBusinessModel, setSelectedBusinessModel] = useState<BusinessModelType>(LocalStorage.get<string>("business_model") as BusinessModelType);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);
  const merchant = useMerchant();
  const whitelabel = useAppSelector(state => state.whitelabel);
  const user = useAuth()?.user;

  // set default businessModel in case it's not set
  useEffect(() => {
    if (selectedBusinessModel === null && user) {
      setBusinessModelBasedOnProgramView(getAvailablePrograms(user));
    }
  }, [selectedBusinessModel, user]);

  // update business model if user's program view has changed - but don't override it if some other user properties has changed and not program_view
  useEffect(() => {
    if (selectedBusinessModel !== null && user.program_view !== null) {
      setBusinessModelBasedOnProgramView(getAvailablePrograms(user))
    }
  }, [user?.program_view]);

  const getAvailablePrograms = (user: IUser) => {
    if (user?.user_type === "MERCHANT") {
      return merchant?.programs;
    } else if (user?.user_type === "WHITELABEL" || user?.user_type === "PARTNER") {
      return whitelabel?.programs;
    }
  }

  const setBusinessModelBasedOnProgramView = (programs) => {
    if (user?.program_view === PROGRAM_VIEWS.SHOW_ALL_DTC_DEFAULT) {
      // if business model is already set, don't change it
      if (!selectedBusinessModel) {
        setBusinessModelTo("DTC");
      }
    }
    else if (user?.program_view === PROGRAM_VIEWS.SHOW_ALL_DTM_DEFAULT) {
      // if business model is already set, don't change it
      if (!selectedBusinessModel) {
        setBusinessModelTo("DTM");
      }
    }
    else if ((user?.program_view === null && (programs as Array<string>).includes("DTC")) || user?.program_view === PROGRAM_VIEWS.HIDE_DTM) {
      setBusinessModelTo("DTC");
    }
    else if ((user?.program_view === null && (programs as Array<string>).includes("DTM")) || user?.program_view === PROGRAM_VIEWS.HIDE_DTC) {
      setBusinessModelTo("DTM");
    }
  }

  const setBusinessModelTo = (bm: BusinessModelType) => {
    if (selectedBusinessModel !== bm) {
      setSelectedBusinessModel(bm);
      LocalStorage.save("business_model", bm);
      Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
    }
  }

  useEffect(() => {
    const businessModelTemp = LocalStorage.get<string>("business_model") as BusinessModelType;
    if (selectedBusinessModel !== businessModelTemp)
      setSelectedBusinessModel(businessModelTemp);
  }, [businessModelUpdated]);

  return selectedBusinessModel;
};