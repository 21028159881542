import styles from "./ProgramViewPopup.module.scss";
import { CloseIcon, Padding } from "common";
import Popup from "./Popup";
import { IPopup } from "common/interfaces";
import _ from "lodash";
import LocalStorage from "classes/LocalStorage";
import { Form, SelectDropdown } from "common/form";
import { Schema, SimpleRequireValidation } from "utils/validation/additionalValidation";
import { useEffect, useState } from "react";
import SystemHandler from "actions/SystemHandler";
import { scrollTop } from "utils/helpers";
import { useAuth } from "auth/useAuth";
import UserHandler from "actions/UserHandler";
import { useAppDispatch } from "reducers/Hooks";
import popUpActions from "reducers/PopUpReducer";

const ProgramViewPopup: React.FC<IPopup> = () => {
  const [programViewOptions, setProgramViewOptions] = useState([]);
  const setUser = useAuth()?.setUser;
  const dispatch = useAppDispatch();

  useEffect(() => {
    SystemHandler.getProgramViewOptions()
      .then(response => {
        setProgramViewOptions(response);
      });
    scrollTop();
  }, [])

  const handleSubmit = async (data: any) => {
    const newProfile = await UserHandler.updateProfile(data);
    setUser(newProfile);
    handleClose();
    dispatch(popUpActions.closePopup());
  }

  const handleClose = () => {
    // so it doesn't open every time user reloads the page, but only on login
    LocalStorage.remove('show_program_view_popup');
  }

  return (
    <Popup isInner={true} maxWidth={800} >
      <>
        <CloseIcon id="programViewPopup" color="dark" onClose={handleClose} />

        <Padding>
          <div className={styles.businessModelWrapper}>
            <h2 className={styles.title}>You can now use the Direct-to-merchant (DTM) program!</h2>
            <p className={styles.subtitle}>Select your preferred program view and the default program to display upon login. You can update these choices anytime in your settings.</p>

            <Form
              id="programViewPopup"
              title=""
              submitBtnText="Save"
              onFormSubmit={handleSubmit}
              validationSchema={Schema({
                program_view: SimpleRequireValidation,
              })}
              values={{
                program_view: "",
              }}
              buttonPosition={"bottom center"}
              inputFields={[[
                <SelectDropdown id="programViewPopup_programView" label="Program view" name="program_view" placeholder="Select" selectOptions={programViewOptions} />
              ]]}
              isInsidePopup
              styleOverride={{ formContainer: { maxWidth: "30rem" } }}
            />

          </div>
        </Padding>
      </>
    </Popup >
  );
};

export default ProgramViewPopup;
